import { Col } from 'components/grid'
import { Link } from 'gatsby'
import React from 'react'
import { Post } from 'templates/news'

interface Props {
	data: Post
}

export default function Card({ data }: Props) {
	return (
		<Col width="w-full sm:w-1/2 lg:w-1/3" className="mb-8">
			<div className="flex h-full flex-col overflow-hidden rounded border shadow ">
				<Link to={`/news/${data.slug}`} className="aspect-news">
					<div
						className="aspect-news w-full bg-cover bg-center bg-no-repeat"
						style={{
							backgroundImage: `url(${data.image ? data.image?.file?.url : '/images/utils/post-placeholder.png'} )`
						}}
					></div>
				</Link>
				<div
					className={`-mt-10 py-1 px-2 text-white ${
						data.category === 'Announcement' || data.category === 'Article' || data.category === 'District Result' ? 'bg-blue-600' : ''
					} ${data.category === 'Press Release' || data.category === 'ARC Updates' ? 'bg-red-600' : ''} relative z-10 max-w-max`}
				>
					<span>{data.category === 'Article' ? 'In the News' : data.category}</span>
				</div>

				<div className="flex w-full flex-auto flex-col p-3">
					<Link to={`/news/${data.slug}`} className="!no-underline">
						<h5 className="mt-4 mb-8 font-normal">{data.title}</h5>
					</Link>

					<div className=" mt-auto flex items-center justify-end">
						<span className="text-sm text-slate-400">{data.publishedDate}</span>
					</div>
				</div>
			</div>
		</Col>
	)
}
